import React, { useState } from "react"
import numeral from "numeral"
import * as Yup from "yup"
import { useMutation } from "@apollo/react-hooks"
import { StripeProvider } from "react-stripe-elements"
import { gql } from "apollo-boost"
import { injectStripe, Elements, CardElement } from "react-stripe-elements"
import { Formik, Form } from "formik"
import Effect from "../formik-fields/Effect"
import { FormControlLabel, Checkbox } from "@material-ui/core"
import Button from "../ui/Button"
import { CheckoutFooter, FormGroupContainer } from "./ui"
import { AddressForm, getAddressValidation } from "./AddressForm"
import Error from "../ui/Error"
import useCart from "../../hooks/useCart"
import usePoster from "../../hooks/usePoster"

const STRIPE_API_KEY =
  process.env.NODE_ENV === "development"
    ? "pk_test_WWAHF7IzOtdCbQ6ZzW44n6iU"
    : "pk_live_vhAG7fXp7YnEYpFR2PfmsuYc"

class CardSection extends React.Component {
  render() {
    return <CardElement onChange={this.props.onChange} />
  }
}

const isFormikStateValid = ({ errors }) =>
  !errors || errors === {} || Object.keys(errors).length === 0

class BillingAddressForm extends React.Component {
  handleSubmit = data => {}

  shouldComponentUpdate(nextProps, nextState) {
    return false
  }

  handleChangeEffect = (currentFormikState, nextFormikState) => {
    this.props.onChange(
      isFormikStateValid(nextFormikState),
      nextFormikState.values
    )
  }

  render() {
    return (
      <Formik
        onSubmit={this.handleSubmit}
        validationSchema={Yup.object(getAddressValidation())}
        initialValues={this.props.initialValues || {}}
        render={props => {
          return (
            <Form>
              <AddressForm initialValues={{}} />
              <Effect onChange={this.handleChangeEffect} />
            </Form>
          )
        }}
      />
    )
  }
}

const CREATE_ORDER = gql`
  mutation CreateOrder(
    $name: String!
    $email: String!
    $paymentToken: String!
    $shippingAddress: AddressInput!
    $shippingMethodID: String!
    $orderItems: [ItemInput!]!
  ) {
    createOrder(
      data: {
        name: $name
        paymentToken: $paymentToken
        email: $email
        shippingAddress: $shippingAddress
        shippingMethodID: $shippingMethodID
        items: $orderItems
      }
    ) {
      id
      orderedAt
      email
      chargeAmount
      items {
        id
        data
      }
    }
  }
`

const CheckoutForm = ({ billingSameAsShipping, onComplete, stripe, order }) => {
  const [state, _setState] = useState({
    billingSameAsShipping: billingSameAsShipping || true,
    billing: order.billingAddress || {},
    billingIsValid: true,
    paymentIsValid: false,
    processing: false
  })

  const setState = data => {
    _setState({ ...state, ...data })
  }

  const [poster, updatePoster] = usePoster()
  const { total, clear, cartItems: items, selectedItem } = useCart()
  const [createOrder, { data, error: orderError, loading }] = useMutation(
    CREATE_ORDER
  )

  const toggleSameAsShipping = () => {
    const billingSameAsShipping = !state.billingSameAsShipping
    const billing = billingSameAsShipping ? {} : state.billing
    const billingIsValid = billingSameAsShipping ? true : false
    setState({ billingSameAsShipping, billing, billingIsValid })
  }

  const handleBillingChange = (billingIsValid, billing) => {
    setState({ billing, billingIsValid })
  }

  const handleChangeCard = ({ complete, error, empty }) => {
    const paymentIsValid = !empty && complete && !error
    setState({ paymentIsValid })
  }

  const handleSubmit = async () => {
    setState({ processing: true })

    const { billing, billingSameAsShipping } = state
    const { shippingAddress } = order
    const address = billingSameAsShipping ? shippingAddress : billing
    const tokenPayload = {
      name: `${address.first_name} ${address.last_name}`,
      address_line1: address.address1,
      address_line2: address.address2,
      address_city: address.city,
      address_state: address.state_code,
      address_country: address.country_code,
      address_zip: address.zip
    }
    const { token } = await stripe.createToken(tokenPayload)

    // this.props.onComplete({
    //   paymentToken: token.id,
    //   billing: address,
    //   billingSameAsShipping,
    // })

    try {
      const data = order
      const variables = {
        name: `${data.shippingAddress.first_name} ${data.shippingAddress.last_name}`,
        email: data.email,
        paymentToken: token.id,
        shippingMethodID: data.shippingMethod.id,
        orderItems: items.map(item => ({
          quantity: item.quantity,
          data: item.posterData,
          name: `Item ${item.id}`,
          framed: item.posterData.framed
        })),
        shippingAddress: data.shippingAddress
      }

      const result = await createOrder({
        errorPolicy: "all",
        variables
      })

      if (result.errors) {
        setState({ error: result.errors[0].message, processing: false })
      } else {
        const order = result.data.createOrder
        updatePoster(selectedItem)
        clear()
        onComplete({ completedOrder: order })
      }
    } catch (error) {
      setState({
        error: "There was an error processing your order.",
        processing: false
      })
    }
  }

  const renderButton = () => {
    const { processing } = state
    const isValid = state.billingIsValid && state.paymentIsValid
    const { shippingMethod } = order
    const totalWithShipping = total + shippingMethod.rate
    const amountFormatted = numeral(totalWithShipping).format("$0,0.00")
    const buttonLabel = processing
      ? `Processing...`
      : `${amountFormatted} | Complete Order`
    const disabled = !isValid || processing

    return (
      <Button
        label={buttonLabel}
        type="submit"
        type="contained"
        disabled={disabled}
        onClick={handleSubmit}
        primary
      />
    )
  }

  const { error } = state
  return (
    <div>
      <h3>Enter payment information</h3>
      <FormGroupContainer style={{ flexGrow: 1, borderLeft: "2px solid #000" }}>
        <CardSection onChange={handleChangeCard} />
      </FormGroupContainer>

      <h3>Confirm billing address</h3>
      <FormGroupContainer>
        <FormControlLabel
          color="default"
          control={
            <Checkbox
              style={{ padding: 0, paddingLeft: 10 }}
              checked={state.billingSameAsShipping}
              color="default"
            />
          }
          label="Same as shipping address"
          onChange={() => toggleSameAsShipping()}
        />

        {!state.billingSameAsShipping ? (
          <BillingAddressForm
            onChange={handleBillingChange}
            initialValues={state.billing}
          />
        ) : null}
      </FormGroupContainer>
      {error ? <Error message={error} /> : null}
      <CheckoutFooter>{renderButton()}</CheckoutFooter>
    </div>
  )
}

const PaymentMethod = props => {
  const InjectedCheckoutForm = injectStripe(CheckoutForm)
  return (
    <StripeProvider apiKey={STRIPE_API_KEY}>
      <Elements>
        <InjectedCheckoutForm {...props} />
      </Elements>
    </StripeProvider>
  )
}
export default PaymentMethod
