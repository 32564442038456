import useStorage from "./useStorage"
import pick from "lodash/pick"

const defaultState = {
  email: null,
  paymentToken: null,
  shippingMethod: null,
  shippingAddress: {},
  billingAddress: {}
}

const useOrder = () => {
  // Make sure we don't persist all the data
  const orderReducer = value => {
    return pick(value, ["email", "shippingAddress", "billingAddress"])
  }

  const [order, setOrder] = useStorage("order.0.2", defaultState, orderReducer)
  const updateOrder = data => {
    setOrder({ ...order, ...data })
  }
  return [order, updateOrder]
}

export default useOrder
