import React from 'react'
import Button from '../ui/Button'
import { gold } from '../ui/theme'
import { navigate } from 'gatsby'

class OrderComplete extends React.Component {
  render() {
    const { order, shippingMethod } = this.props
    return (
      <div>
        <div style={{ padding: 20 }}>
          <h3>You order has been placed!</h3>
          <p>
            Here’s what happens next:
            <ul>
              <li>
                You order is sent off for fulfillment (usually takes 3-5 days)
              </li>
              <li>
                Your order is shipped to you ({shippingMethod.description})
              </li>
              <li>
                You’ll get an email receipt (sent to {order.email}) for your
                payment and another email once your order has shipped.
              </li>
            </ul>
          </p>
          <p>
            Don’t hesitate to contact me with any questions at all. Just shoot
            an email to <a href="mailto:mark@tiltmaps.com">mark@tiltmaps.com</a>
            .
          </p>
          Thanks for your support,
          <br />
          Mark
        </div>
        <footer style={{ padding: 10, textAlign: 'center' }}>
          <Button
            label="Done"
            type="submit"
            foregroundColor={gold}
            backgroundColor={'#000'}
            type="contained"
            onClick={() => {
              navigate('/')
            }}
            primary
          />
        </footer>
      </div>
    )
  }
}

export default OrderComplete
