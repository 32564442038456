import React from "react"
import styled from "styled-components"
import * as Icon from "react-feather"
import { Link, navigate } from "gatsby"
import CartItem from "./CartItem"
import useCart from "../../hooks/useCart"
import usePoster, { defaultPoster } from "../../hooks/usePoster"

const AddLink = styled(Link)`
  display: flex;
  height: 40px;
  align-items: center;
  background-color: ${p => p.theme.palette.background.paper};
  border: 1px solid ${p => p.theme.palette.divider};
  color: ${p => p.theme.palette.text.primary};
  padding-left: 10px;
  svg {
    margin-right: 4px;
  }
`

const Footer = styled.div`
  text-align: right;
  padding: 20px;
  color: ${p => p.theme.palette.text.primary};
`

const Cart = () => {
  const [poster, updatePoster] = usePoster()
  const {
    cartItems,
    total,
    selectItem,
    selectedItemIndex,
    removeItem,
    updateItem
  } = useCart()

  const handleToggleFrame = item => {
    const framed = !item.posterData.framed
    updateItem(item.id, { framed })
  }

  const handleSetQuantity = (id, quantity) => {
    updateItem(id, {}, quantity)
  }

  const handleAddNewPoster = () => {
    updatePoster(defaultPoster, true)
    navigate("/editor")
  }

  return (
    <div>
      {!cartItems.length ? <div>Nothing in your cart</div> : null}
      {cartItems.map((item, index) => {
        const isSelected = index === selectedItemIndex
        return (
          <CartItem
            key={index}
            index={index}
            isSelected={isSelected}
            item={item}
            removeItem={id => removeItem(id)}
            selectItem={selectItem}
            setQuantity={handleSetQuantity}
            toggleFrame={handleToggleFrame}
          />
        )
      })}
      <div>
        <AddLink
          onClick={e => {
            e.preventDefault()
            handleAddNewPoster()
          }}
        >
          <Icon.Plus />
          Add a new poster
        </AddLink>
      </div>
      <Footer>
        Total: <strong>${total}</strong>
      </Footer>
    </div>
  )
}

export default Cart
