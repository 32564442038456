import React from 'react'
import styled from 'styled-components'

const ErrorContainer = styled.div`
  background-color: #fff;
  padding: 10px;
  color: #000;
  border: 1px solid #ef5350;
  margin: 5px;
  h3 {
    text-align: center;
  }
`

const Error = ({ message, showSupportLink }) => {
  return (
    <ErrorContainer>
      <>
        <h3 style={{ fontSize: 18, margin: 0, marginBottom: 5 }}>🤦‍ Oops!</h3>
        <span>{message}</span>
        {showSupportLink ? (
          <span>
            You may contact support <a href="mailto:mark@tiltmaps.com">here</a>.
          </span>
        ) : null}
      </>
    </ErrorContainer>
  )
}

Error.defaultProps = {
  showSupportLink: true,
}

export default Error
